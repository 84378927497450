<template>
  <dialog-outline v-model="showDialog" max-width="100%">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- Report Header -->
      <reporting-card
        :report-module="reportModule"
        @download-report="onReportDownload"
      >
        <template v-slot:rightToolbar>
          <div style="width: 350px;">
            <v-select
              class="mt-1"
              v-model="tableHeaders"
              :items="selectedHeaders"
              :item-text="selectedHeaders.text"
              :item-value="selectedHeaders.visible"
              label="Table Headers"
              multiple
              persistent-hint
              return-object
              @change="onSelectedHeaderChange"
              outlined
              dense
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span
                  v-if="index === 2"
                  class="text-grey text-caption align-self-center"
                >
                  (+{{ tableHeaders.length - 2 }} others)
                </span>
              </template>
            </v-select>
          </div>
        </template>
      </reporting-card>

      <!-- Report Context -->
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :hide-default-footer="true"
        disable-pagination
        ref="table"
        @click:row="handleClick"
        style="cursor: pointer"
        :sort-by.sync="sortKey"
        :sort-desc.sync="sortDesc"
        :custom-sort="customSort"
      >
        <template v-slot:item.category="{ item }">
          {{ getItemCategory(item) }}
        </template>

        <template v-slot:item.status="{ item }">
          {{ getStatusText(item) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <base-button icon @click="onDelete(item)">
            <template v-slot:icon-name>
              {{ iconDelete }}
            </template>
          </base-button>
        </template>
      </v-data-table>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// mixins
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";
import { reportableMixin } from "@/mixins/shared/report/reportableMixin";

//model
import { findCategoryType } from "@/model/category/categoryModel";
import { headerText } from "@/model/common/dataTable/dataTableConst";
import { iconTrash } from "@/design/icon/iconConst";
import {moduleNames} from "@/model/solution/moduleModel";
import {findProjectState} from "@/model/workflow/project/projectModel";

export default {
  name: "ReportDialog",
  mixins: [baseComponentMixin, recordBannerAble, reportableMixin],
  props: {
    recordList: {
      type: Array,
      default: undefined
    },
    searchHeaders: {
      type: Array,
      default: undefined
    },
    showDetails: {
      type: Function,
      default: undefined
    },
    reportModule: {
      type: String,
      default: "Folder"
    }
  },

  data() {
    return {
      selectedHeaders: this.searchHeaders,
      tableHeaders: this.searchHeaders,
      tableModel: undefined,
      sortKey: "",
      sortDesc: "",
      iconDelete: iconTrash
    };
  },

  computed: {
    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.iconReport;
    },

    /**
     * Get Move Page Dialog title
     * @return {string} Move Page Dialog title
     */
    dialogTitle() {
      return `Detailed ${this.getReportModuleTitle} View`;
    },

    /**
     * Get Current Date
     * @return {Date}
     */
    currentDate() {
      return new Date();
    },

    /**
     * Get Table Record List
     * @return {*[]}
     */
    items() {
      return this.recordList;
    },

    /**
     * Get Report Module Title
     * @return {String|string}
     */
    getReportModuleTitle() {
      if (this.reportModule === moduleNames.Folder) return "Records";
      return this.reportModule;
    }
  },

  methods: {
    /**
     * Get Category Name
     * @param item - Record
     * @return {string}
     */
    getItemCategory(item) {
      return findCategoryType(item?.categoryId ?? -1).name;
    },

    /**
     * Get Project Status Text
     * @param item - Project
     * @return {string}
     */
    getStatusText(item) {
      return findProjectState(item?.status ?? -1).name;
    },

    /**
     * OnChange Selected Headers
     * @param headers
     */
    onSelectedHeaderChange(headers) {
      this.updateSearchHeaders(headers);
    },

    /**
     * Update Search Headers
     * @param items
     */
    updateSearchHeaders(items) {
      try {
        const itemTypes = items.map(obj => obj.text);

        // Set header visibility based upon user selection
        const updatedHeaders = this.tableHeaders.map(header => {
          if (!itemTypes.includes(header.text)) {
            return { ...header, visible: false };
          } else {
            return header;
          }
        });

        // Get and set Headers that are visible
        this.tableHeaders = updatedHeaders.filter(el => !!el.visible);

        // Sort Headers with the Action Header at end pattern
        this.tableHeaders.sort(function(a, b) {
          if (a.text === headerText.actions) {
            return 1; // Move a to the end of the array
          } else if (b.text === headerText.actions) {
            return -1; // Move b to the end of the array
          } else {
            return 0; // Keep the order of other objects
          }
        });
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Sort the items array based of header selection (sorting)
     * @return {*[]}
     */
    sortedItems() {
      try {
        return this.items.slice().sort((a, b) => {
          const sortVal = this.sortDesc ? -1 : 1;
          const firstSortValue =
            a[this.sortKey] || this.getFieldValue(a, this.sortKey);
          const secondSortValue =
            b[this.sortKey] || this.getFieldValue(b, this.sortKey);

          return firstSortValue === null && secondSortValue === null
            ? 0
            : firstSortValue === null
            ? sortVal * -1
            : secondSortValue === null
            ? sortVal
            : firstSortValue < secondSortValue
            ? sortVal * -1
            : firstSortValue > secondSortValue
            ? sortVal
            : 0;
        });
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Event to Handle Click Operation on Table Row
     * Opens Record Detail Dialog
     * @param item - Record
     */
    handleClick(item) {
      try {
        if (this.showDetails) this.showDetails(item);
      } catch (e) {
        console.error(e);
      }
    }
  },
  watch: {
    searchHeaders(newValue) {
      this.tableHeaders = newValue;
      this.selectedHeaders = newValue;
    }
  }
};
</script>

<style scoped>
::v-deep .v-data-table-header {
  background-color: lightskyblue;
}

.selected {
  background-color: red;
}
</style>
